import React from 'react';
import styled from 'styled-components';
import NavBar from "./Components/NavBar";
import Home from "./Components/Home";
import Coda from "./Components/Coda";
import About from "./Components/About";
import Partners from "./Components/Partners";
import Content from "./Components/Content";
import { ModalProvider } from './context/ModalContext';
import Modal from './Components/Modal';
import PressRoom from './Components/PressRoom';

const Container = styled.div`
  scroll-behavior: smooth; /* Native smooth scrolling */
`;

const Section = styled.section`
  position: relative;

  ${({ id }) => id !== "home" && "padding-top: 80px;"}
  ${({ id }) => id === "home" && "margin-top: -80px;"} /* Counteract navbar height */
  ${({ id }) => id === "about" && "margin-top: -80px;"} /* Counteract navbar height */

`;

function App() {
  return (
    <ModalProvider>
      <Container>
        <NavBar />
        <Section id="home">
          <Home />
        </Section>
        <Section id="coda">
          <Coda />
        </Section>
        <Section id="about">
          <About />
        </Section>
        <Section id="Press Room">
          <PressRoom />
        </Section>
        <Section id="partners">
          <Partners />
        </Section>
        <Section id="content">
          <Content />
        </Section>
        <Modal />
      </Container>
    </ModalProvider>
  );
}

export default App;
