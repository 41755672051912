import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  gap: 150px;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  @media (max-width: 1024px) {
    gap: 80px; /* Reduce gap for mobile */
  }

  /* background-color: red; */
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
  width: 100%; /* Set section width to 100% */
  opacity: 0;
  transform: ${(props) => props.$slidedirection};
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  padding: 0; /* Remove padding */
  margin-top: ${(prop) => prop.$marginTop ? prop.$marginTop : 0};
  &.visible {
    opacity: 1;
    transform: translateX(0);
  }

  @media (min-width: 1025px) {
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
`;
const Image = styled.img`
  width: 30%; /* Image takes 30% of the width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers its container without distortion */
  margin: 0; /* No margin */
  padding: 0; /* No padding */
  @media (max-width: 1024px) {
    display: none; /* Hide image on small screens */
  }
  `
;
const ImageForMobile = styled.img`
  width: 100%;
  height: 500px;
  object-fit: fill;
  margin: 0; /* No margin */
  padding: 0; /* No padding */
  display: none;
  @media (max-width: 1024px) {
    display: block; /* Hide all images on small screens */
  }
`;
const Text = styled.div`
  width: 70%; /* Text takes 70% of the width */
  font-size: 1.2rem;
  line-height: 1.5;
  margin-left: 20px; /* Add left margin to separate from the image */
  margin-right: 20px; /* Add right margin for spacing */
  text-align: ${(props) => (props.$alignright ? 'right' : 'left')};
  margin-left: ${(props) => (props.$alignright ? '0px' : '50px')};
  margin-right: ${(props) => (props.$alignright ? '50px' : '0px')};
  
  @media (min-width: 1025px) {
    h2, p {
      text-align: ${(props) => (props.$alignright ? 'right' : 'left')};
    }
  }
  
  @media (max-width: 1024px) {
    width: 100%; /* Full width for mobile */
    text-align: center; /* Center text */
    margin-left: 0; /* Reset margin on small screens */
    margin-right: 0; /* Reset margin on small screens */
  }
`;

const Title = styled.h1`
  font-size: 2.8rem; /* Smaller font size */
  color: #7B1BB4; /* Professional color */
  text-align: center; /* Centered text */
  margin: 10px 0; /* Reduced margin */
  line-height: 1.4; /* Better line height for readability */
  padding: 12px;
`;

const SectionNumber = styled.span`
  font-size: 10rem; /* Slightly larger font size */
  font-weight: bold; /* Bold text */
  color: #7B1BB4; /* Professional color */
  margin-right: 10px; /* Space between number and title */
  
`;

const ContainerIntro = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  margin-top: -150px;
  @media (max-width: 1024px) {
    margin-top: 0px;
    width: 90%;
  }
`;

const BreakText = styled.p`
  font-size: 10em;
  flex-grow: 2; /* Increase to bring it closer to the text */
  margin-right: 10px; /* Optional: Add some space between the two */
  color: #7B1BB4; /* Professional color */
  @media (max-width: 1024px) {
    display: none;
  }
`;

const IntroText = styled.p`
  flex-grow: 3; /* Reduce the flex-grow so it doesn't take up too much space */
  font-size: 2rem; /* Slightly larger for visibility */

  text-align: center; /* Centered text */
  font-weight: bold;
  color: #333; /* Darker color for contrast */
  margin: 10px 50px; /* Adjust spacing */
  margin-top: 100px;
  font-family: Merriweather, Menlo, Monaco, Consolas, 'Courier New', monospace;
  line-height: 1.5em;

  @media (max-width: 1240px) {
    font-size: 1.3rem; /* Slightly larger for visibility */
    margin: 10px 50px; /* Adjust spacing */
  }
`;

const TitleCoda = styled.h1`
  font-size: 2em;
  @media (max-width: 1240px) {
    font-size: 1.8em;
  }

`;

const SubTitleCoda = styled.p`
  font-size: 2em;
  @media (max-width: 1240px) {
    font-size: 1.2em;
  }
`;

export default function Coda() {
  return (
    <Container>
      {/* Wrap title and intro in a single InViewSection */}
      <InViewSection $slidedirection="translateY(-100px)">
        <div style={{ textAlign: 'center', width: '100%',display:'flex' ,flexDirection:'column',alignItems:'center'}}>
          <Title>
            Our CODA AI avatars use advanced machine learning to translate spoken and written language into real-time sign language.
          </Title>
          <ContainerIntro>
            <BreakText>{'['}</BreakText>
              <IntroText>
              Our system recognizes and translates complex sentences into sign language gestures, providing seamless communication and ensuring accessibility for the deaf and hard-of-hearing community across various platforms.
            </IntroText>
            <BreakText>{']'}</BreakText>
          </ContainerIntro>

        </div>
      </InViewSection>

      {/* First Section */}
      <InViewSection $slidedirection="translateX(-100px)" $marginTop="-100px">
      <Image src="/compressed_83ca18bc4fc6e8bb7760e71f7ca4a85b.webp" alt="Image 1" />
      <Text>
          {/* <SectionNumber>1</SectionNumber> */}
          <TitleCoda>In Video Calls</TitleCoda>
          <SubTitleCoda>
          Experience accessible communication with our avatar that translates spoken words into sign language during video calls. Whether for personal conversations or professional meetings, everyone can be part of the conversation, ensuring no one is left behind.
          </SubTitleCoda>
        </Text>
      </InViewSection>

      {/* Second Section */}
      <InViewSection $slidedirection="translateX(100px)" $marginTop="-100px">
        <Image src="/Education.png" alt="Image 2" />
        <Text $alignright>
          {/* <SectionNumber>2</SectionNumber> */}
          <TitleCoda>In Education</TitleCoda>
          <SubTitleCoda>Attach our AI sign language avatar to class videos, enabling deaf students to follow lessons in their native language. The avatar signs real-time translations of the lectures, ensuring full comprehension of the material for all students.</SubTitleCoda>
        </Text>
      </InViewSection>

      {/* Third Section */}
      <InViewSection $slidedirection="translateX(-100px)" $marginTop="-100px">
        <Image src="/InMedia.jpeg" alt="Image 3" style={{marginRight:'20px'}}/>
        <Text>
          {/* <SectionNumber>3</SectionNumber> */}
          <TitleCoda>In Media</TitleCoda>
          <SubTitleCoda>Our AI-powered sign language avatars bring inclusivity to recorded media content, ensuring that the deaf and hard-of-hearing community can enjoy films, sports, and more without barriers.</SubTitleCoda>
        </Text>
      </InViewSection>
         {/* <ImageForMobile src="/compressed_83ca18bc4fc6e8bb7760e71f7ca4a85b.webp" alt="Image 4" /> */}
    </Container>
  );
}

// Component to handle the intersection observer and apply animations
const InViewSection = ({ children, $slidedirection , $marginTop }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.4, // Trigger when 10% of the element is visible
  });

  return (
    <Section ref={ref} className={inView ? 'visible' : ''} $slidedirection={$slidedirection} $marginTop = {$marginTop}>
      {children}
    </Section>
  );
};
