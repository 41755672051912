import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

// Styled components
const HomeContainer = styled.div`
  height: calc(100vh);
  position: relative;
`;

const BgBackgrounud = styled.div` 
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
 height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align items to the bottom */
  align-items: flex-end; /* Align items to the right */
  padding: 0;
  text-align: right; /* Right-align text */
  margin: 0;
background-image: url('/PHOTO-2024-09-11-09-50-52.jpg');
  background-repeat: no-repeat;
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the background image */
  position: relative; /* Required for the pseudo-element */


  @media (max-width: 1024px) {
    justify-content: center; /* Center items on smaller screens */
    align-items: center;
    text-align: center; /* Center text for smaller screens */
    filter: blur(4px);
    -webkit-filter: blur(4px);
  }
`;

const CodaText = styled.h1`
  font-size: 13rem; /* Default size for larger screens */
  color: #fff;
  font-weight: bold;
  margin: 0; /* Remove any margin */
  @media (max-width: 1024px) {
    -webkit-text-stroke: 1px black; /* Stroke with black color and 2px width */
    font-size: 20vw; /* Adjust size for mobile */
    color: #fff;
  }
`;

const IntroText = styled.p`
  font-size: 1.5rem; /* Default size for larger screens */
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin: 0; /* Remove any margin */

  @media (max-width: 1024px) {
    color: #fff;
    -webkit-text-stroke: .5px black; /* Stroke with black color and 2px width */
    font-size: 5vw; /* Adjust size for mobile */
  }
`;

const TextLeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 95vw;
  height: calc(100vh);
  overflow-x: hidden; /* Prevent horizontal scrolling */
  display: flex;
  align-items: flex-end; /* Align items to the bottom */
  justify-content: flex-end; /* Align items to the right */
  
  @media (max-width: 1024px) {
    align-items: center; /* Center items on smaller screens */
    justify-content: center;
  }
`;

export default function Home() {
  return (
    <HomeContainer>
      <BgBackgrounud/>
      <Container>
        <TextLeftContainer>
          <CodaText>CODA</CodaText>
          <IntroText>Breaking Barriers, Inspired by Deaf Parents</IntroText>
        </TextLeftContainer>
      </Container>
    </HomeContainer>
  );
}
