import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  padding: 20px; /* Keep padding equal on all sides */
  background-image: url('/Hello.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1024px) {
    /* background-image: url('/HelloMobile.jpg'); */
    background-position: center;
    background-image: url('/HelloMobile.jpg');
    padding: 20px; /* Keep padding equal */
  }
`;

const Form = styled.form`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px; /* Adjust padding to fit mobile screen better */
  padding-right: 40px;
  max-width: 550px; /* Use a percentage to ensure it fits on mobile */
  width: 100%;
  
  @media (max-width: 1024px) {
    padding: 15px; /* Keep equal padding on all sides */
    padding-right: 30px;
    margin: 150px auto 0 auto; /* Center the form horizontally */

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    max-width: 80%; /* Use a percentage to ensure it fits on mobile */
  }
`;

const FormField = styled.div`
  margin-bottom: 20px; /* Increased space between form fields */

  @media (max-width: 1024px) {
    margin-bottom: 15px; /* Adjust spacing on mobile */
  }
`;

const Label = styled.label`
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #7b1bb4;

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px; /* Adjust padding for mobile */
  font-size: 1rem; /* Adjust font size for mobile */
  border: 1px solid #ddd;
  border-radius: 4px;

  @media (max-width: 1024px) {
    padding: 10px; /* Adjust padding for mobile */
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px; /* Adjust padding for mobile */
  font-size: 1rem; /* Adjust font size for mobile */
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;

  @media (max-width: 1024px) {
    padding: 10px; /* Adjust padding for mobile */
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 15px; /* Adjust button padding */
  background-color: #7B1BB4;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem; /* Adjust font size for mobile */
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5E0891;
  }

  @media (max-width: 1024px) {
    padding: 15px;
    font-size: 1rem; /* Adjust font size for mobile */
  }
`;

const Message = styled.p`
  color: ${props => (props.success === 'true' ? 'green' : 'red')};
  margin-top: 15px;

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

export default function Content() {
  const formRef = useRef();
  const [message, setMessage] = useState({ text: '', success: true });

  const saveEmailToDatabase = async (email) => {
    try {
      await addDoc(collection(db, 'emails'), {
        email: email,
        timestamp: new Date(),
      });
      console.log('Email saved to database');
    } catch (error) {
      console.log('Error saving email:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = formRef.current['email'].value; // Extract the email from the form

    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      formRef.current,
      process.env.REACT_APP_PUBLIC_KEY
    )
    .then((result) => {
      console.log('Email sent:', result.text);
      setMessage({ text: 'Your message has been sent!', success: true });

      saveEmailToDatabase(email);

      formRef.current.reset();
    }, (error) => {
      console.log('Email sending failed:', error.text);
      setMessage({ text: 'Failed to send your message. Please try again.', success: false });
    });
  };

  return (
    <FormContainer>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormField>
          <Label htmlFor="name">Name</Label>
          <Input type="text" id="name" name="name" required placeholder="What's your name?" />
        </FormField>
        <FormField>
          <Label htmlFor="email">Email</Label>
          <Input type="email" id="email" name="email" required placeholder="Your best email address" />
        </FormField>
        <FormField>
          <Label htmlFor="message">Message</Label>
          <Textarea id="message" name="message" rows="5" required placeholder="How can we help you today?" />
        </FormField>
        <SubmitButton type="submit">Submit</SubmitButton>
        {message.text && <Message success={message.success.toString()}>{message.text}</Message>}
      </Form>
    </FormContainer>
  );
}
