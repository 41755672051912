import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #efefef; /* Light background for contrast */
  min-height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scrolling */
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #7b1bb4; /* Primary color */
  margin-bottom: 60px;
`;

const TextContainer = styled.div`
  text-align: justify; /* Justified text for a cleaner look */
  line-height: 1.6; /* Improved line spacing */
  font-size: 1.2rem; /* Adjust font size */
  color: #333; /* Darker text for contrast */
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Slightly move down */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Animation */

  &.visible {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move back to original position */
  }
`;

const Signature = styled.p`
  margin-top: 40px;
  font-style: italic; /* Italic style for signature */
  font-weight: bold;
`;

const ContainerText = styled.div`
  max-width: 700px; /* Limit text width for readability */
  margin: 0 20px; /* Center text with side margins */
  margin-right: 50px;
`;

const ContainerImg = styled.img`
  background-color: red;
  min-width: 700px; /* Limit image width for readability */
  margin-left: 50px;

  @media (max-width: 1240px) {
    display: none; /* Hide image on small screens */
  }
`;

const BigTextTop = styled.h1`
  display: block;
  font-size: 10rem;
  margin-bottom: -150px;
  margin-top: -70px;
  color:#7b1bb4;
`;

const BigTextBottom = styled.h1`
  display: block;
  font-size: 10rem;
  margin-top: -60px;
  margin-bottom: -150px;
  text-align: right;
  color:#7b1bb4;
`;

const PText = styled.p`
  font-family: 'Caveat', PlayfairDisplay, Monaco, Consolas, 'Courier New', monospace;
  font-size: 2rem;
`;

const Img = styled.img`
 @media (min-width: 1240px) {
    display: none; /* Hide image on small screens */
  }
`;

const InViewSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.07, // Trigger when 30% of the element is visible
  });

  return (
    <TextContainer ref={ref} className={inView ? 'visible' : ''}>
      {children}
    </TextContainer>
  );
};

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;
  gap: 80px;

  @media (max-width: 1240px) {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; /* Center items */
  }
`;

export default function About() {
  return (
    <Container>
      <Title>About Us</Title>
      <InViewSection>
        <BodyContainer>
          <ContainerImg src='/IMG_0142.JPG' alt='CEO img' />
          <ContainerText>
            <BigTextTop>"</BigTextTop>
            <PText>From the Desk of Shani,</PText>
            <PText>
              Growing up as a child of deaf parents, I learned firsthand the unique challenges faced by the deaf community. My home was filled with love and conversation, and my parent's courage and perseverance inspired me to create something meaningful. I knew technology could bridge the communication gap, and that’s why I founded CODA. Our mission is simple: to make communication more inclusive by developing AI avatars capable of translating spoken and written language into sign language in real-time.
            </PText>
            <PText>
              For me, this isn’t just a business venture; it’s personal. Every avatar we create, every sign translated, is a step towards building a world where no one is left out of the conversation. I know what it means for a deaf person to have access, and for their families to feel connected. With CODA, we are using AI not just as a tool, but as a bridge to understanding, equality, and connection for millions of people worldwide.
            </PText>
            <PText>
              Thank you for joining us on this journey to empower the deaf community and create a more accessible world for all.
            </PText>
            <BigTextBottom>"</BigTextBottom>
            <Signature>
              Warm regards,<br />
              Shani Bibi<br />
              Founder & CEO, CODA
            </Signature>
          </ContainerText>
          <Img src='/IMG_0142.JPG' alt='CEO img'  style={{width:'100vw',height:'700px'}}/>
        </BodyContainer>
      </InViewSection>
    </Container>
  );
}
