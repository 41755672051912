import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Sample partner data
const partners = [
  { id: 1, src: '/PressOne.jpeg', alt: 'PressOne', link: 'https://www.jns.org/israeli-startups-step-in-to-fill-wartime-gaps/' },
  { id: 2, src: '/PressTwo.jpeg', alt: 'PressTwo', link: 'https://www.linkedin.com/posts/eido-minkovsky-039905103_%D7%9E%D7%99%D7%99-%D7%95%D7%95%D7%9E%D7%9F-shani-bibi-%D7%9E%D7%A7%D7%99%D7%9E%D7%AA-%D7%94%D7%9E%D7%99%D7%96%D7%9D-%D7%94%D7%9E%D7%95%D7%A4%D7%9C%D7%90-coda-activity-7074339239882047490-WMjY/?originalSubdomain=il' },
  { id: 3, src: '/PressThree.jpeg', alt: 'PressThree', link: 'https://nocamels.com/2023/08/ai-avatar-turns-speech-into-sign-language-in-real-time/#:~:text=Israeli%20startup%20CODA%20is%20aiming,into%20sign%20language%20almost%20instantaneously' },
  { id: 4, src: '/PressFour.jpeg', alt: 'PressFour', link: 'https://datafort.com/revolutionizing-accessibility-the-emergence-of-ai-generated-avatars-in-coda/' },
  { id: 5, src: '/PressFive.jpeg', alt: 'PressFive', link: 'https://madan.org.il/en/news/israeli-startup-coda-created-avatar-sign-language-interpreter' },

  // Add more partners as needed
];

// Carousel styling
const CarouselContainer = styled.div`
  width: 80%; /* Adjust width as needed */
  margin: 0 auto; /* Center the carousel */
  padding: 20px 0; /* Optional: Add padding */
  height: 500px;
  /* background-color: #efefef; */
  border-radius: 18px;
`;

const PartnerImage = styled.img`
  width: 100%; /* Adjust the width of the images */
  height: 500px; /* Maintain aspect ratio */
  object-fit: fill; /* Ensure images cover the space */
  border-radius: 10px; /* Optional: add some border radius for rounded edges */
  cursor: pointer;
  @media (max-width: 1024px) {
    width: 100%; /* Adjust the width of the images */
    height: 300px; /* Maintain aspect ratio */
  }
`;

// Slider styling for spacing
const SlideWrapper = styled.div`
  padding: 0 15px; /* Adjust the padding to create space between slides */
  box-sizing: border-box; /* Ensure padding does not affect total width */
`;

const PressRoom = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  const ContainerDiv =  styled.div`
  @media (max-width: 1024px) {
   margin-bottom: -250px;
  }
`;
  return (
    <ContainerDiv>
      <h1 style={{textAlign:'center',fontSize:'2.5rem',color:'#7b1bb4'}}>Press Room</h1>
      <CarouselContainer>
        <Slider {...settings}>
          {partners.map(partner => (
            <SlideWrapper key={partner.id}>
              {/* Make the image clickable by wrapping it in an <a> tag */}
              <a href={partner.link} target="_blank" rel="noopener noreferrer">
                <PartnerImage src={partner.src} alt={partner.alt} />
              </a>
            </SlideWrapper>
          ))}
        </Slider>
      </CarouselContainer>
    </ContainerDiv>
  );
};

export default PressRoom;
