import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Sample partner data
const partners = [
  { id: 1, src: '/Nvidia-Logo.wine.png', alt: 'Partner 1' },
  { id: 2, src: '/y_1@)133.png', alt: 'Partner 2' },
  { id: 3, src: '/https___cdn.evbuc.com_images_124768331_109364363945_1_original.jpg', alt: 'Partner 3' },
  { id: 4, src: '/foundation.png', alt: 'Partner 4' },
  { id: 5, src: '/Israel_Innovation_Authority_logo.jpg', alt: 'Partner 5' },
  { id: 6, src: '/kakal.jpg', alt: 'Partner 6' },

  // Add more partners as needed
];

// Carousel styling
const CarouselContainer = styled.div`
  width: 80%; /* Adjust width as needed */
  margin: 0 auto; /* Center the carousel */
  padding: 20px 0; /* Optional: Add padding */
  height: 200px;
  background-color: #efefef;
  border-radius: 18px;
  @media (max-width: 1024px) {
    height: 120px; /* Maintain aspect ratio */

  }
`;

const PartnerImage = styled.img`
  width: 70%; /* Adjust the width of the images */
  height: 200px; /* Maintain aspect ratio */
  object-fit: fill; /* Ensure images cover the space */
  border-radius: 10px; /* Optional: add some border radius for rounded edges */
  @media (max-width: 1024px) {
    width: 100%; /* Adjust the width of the images */
    height: 120px; /* Maintain aspect ratio */

  }
`;

const ContainerDiv =  styled.div`
  @media (max-width: 1024px) {
  }
`;
// Slider styling for spacing
const SlideWrapper = styled.div`
  padding: 0 15px; /* Adjust the padding to create space between slides */
  box-sizing: border-box; /* Ensure padding does not affect total width */
`;

const Partners = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <ContainerDiv>
      <h1 style={{textAlign:'center',fontSize:'2.5rem',color:'#7b1bb4'}}>CODA's Partners</h1>
    <CarouselContainer>
      <Slider {...settings}>
        {partners.map(partner => (
          <SlideWrapper key={partner.id}>
            <PartnerImage src={partner.src} alt={partner.alt} />
          </SlideWrapper>
        ))}
      </Slider>
    </CarouselContainer>
    </ContainerDiv>

  );
};

export default Partners;
