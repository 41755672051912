import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { useModal } from '../context/ModalContext';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket:  process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId:  process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  width: 500px;
  max-width: 90%;
  text-align: center;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: #7b1bb4;
  border: none;
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    color: #5e0891;
  }
`;

const EmailInput = styled.input`
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 12px;
    font-size: 0.9rem;
  }
`;

const RegisterButton = styled.button`
  background: #5e0891;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;

  &:hover {
    background: #7b1bb4;
  }
`;

const Message = styled.p`
  color: ${props => (props.success === 'true' ? 'green' : 'red')};
  margin-top: 15px;
`;

const Modal = () => {
  const { isOpen, closeModal } = useModal();
  const emailRef = useRef(null);
  const [message, setMessage] = useState({ text: '', success: true });

  useEffect(() => {
    if (isOpen) {
      emailRef.current.focus();
    }
  }, [isOpen]);

  const saveEmailToDatabase = async (email) => {
    try {
      await addDoc(collection(db, 'emails'), {
        email: email,
        timestamp: new Date(),
      });
      console.log('Email saved to database');
      setMessage({ text: 'Email saved successfully!', success: true });
    } catch (error) {
      console.log('Error saving email:', error);
      setMessage({ text: 'Error saving email. Please try again.', success: false });
    }
  };

  // Simple email validation function
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleRegister = async () => {
    const email = emailRef.current.value.trim(); // Get the email from input
    if (!validateEmail(email)) {
      setMessage({ text: 'Please enter a valid email address.', success: false });
      return; // Stop execution if the email is invalid
    }
    await saveEmailToDatabase(email);
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={closeModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={closeModal} aria-label="Close modal">&times;</CloseButton>
        <h2>Register</h2>
        <EmailInput
          type="email"
          placeholder="Enter your email"
          ref={emailRef}
          aria-label="Email address"
          required
        />
        <RegisterButton onClick={handleRegister}>Register</RegisterButton>
        {message.text && <Message success={message.success.toString()}>{message.text}</Message>}
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
